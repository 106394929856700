import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { demeterMarketIndicatorsApi } from '../../../Apis/Apis';
import {
    DeleteMarketIndicatorFactorRequest,
    DemeterPermissionType,
    DemeterUserType,
    UpdateMarketIndicatorFactorGroupRequest,
} from '../../../Generated/Raven-Demeter';
import NavigationRoutes from '../../../Layouts/NavigationRoutes';
import { useApplicationSelector } from '../../../Redux/ReduxStore';
import { selectUserType } from '../../../Redux/Slices/UserSlice';
import useApiWithoutAutoExecute from '../../Apis/Hooks/useApiWithoutAutoExecute';
import useCacheThenApi from '../../Apis/Hooks/useCacheThenApiHook';
import useMultipleApis from '../../Apis/Hooks/useMultipleApisHook';
import addNewFactorGroup from '../../Assets/Icons/expandGray.svg';
import MarketIndicatorChart from '../../Components/Charts/MarketIndicator/MarketIndicatorChart';
import PageHeader from '../../Components/Headers/PageHeader';
import PageHeaderWithBackNavigation from '../../Components/Headers/PageHeaderWithBackNavigation';
import PageLoadingSpinner from '../../Components/LoadingSpinner/PageLoadingSpinner';
import MenuByMarketIndicatorNavigation from '../../Components/MenuFileSelectors/MenuByMarketIndicatorNavigation';
import useSearchParameters from '../../Components/Navigation/Hooks/useSearchParametersHook';
import CacheKeys from '../../Services/Cache/CacheKeys';
import useLanguage from '../../Services/Language/useLanguageHook';
import usePermission from '../../Services/Permissions/usePermissionHook';
import MarketIndicatorFactorGroup from './MarketIndicatorFactorGroup';
import styles from './MarketIndicatorFactorsPage.module.scss';
import MarketIndicatorGuageChart from './MarketIndicatorGuageChart';
import MarketIndicatorReviewForm from './MarketIndicatorReviewForm';

interface IMarketIndicatorsProps {
    isInAdministrationMode?: boolean;
}

const defaultNumberOfFactorsInRow = 4;
const maximumNumberOfFactorGroups = 4;

const MarketIndicatorsPage: React.FC<IMarketIndicatorsProps> = (props: IMarketIndicatorsProps) => {
    // Application hooks.
    const [translations, translate] = useLanguage();
    const navigate = useNavigate();
    const { marketIndicatorGuid } = useParams();
    const [searchParameters, setSearchParameters] = useSearchParameters();
    const currentUserType = useApplicationSelector(selectUserType);

    // Api hooks.
    const [, , listMarketIndicatorsResponse] = useCacheThenApi(CacheKeys.ListMarketIndicatorsCurrent, () =>
        demeterMarketIndicatorsApi.listMarketIndicatorsCurrent(),
    );

    const [loadingMarketIndicator, refreshMarketIndicator, getMarketIndicatorResponse] = useApiWithoutAutoExecute(() =>
        demeterMarketIndicatorsApi.getMarketIndicator(marketIndicatorGuid!, true, props.isInAdministrationMode),
    );

    const [loadingRunMarketIndicator, refreshRunMarketIndicator, runMarketIndicatorResponse] = useApiWithoutAutoExecute(
        () => demeterMarketIndicatorsApi.runMarketIndicator(marketIndicatorGuid!),
        { errorMessage: translations.marketIndicators.marketIndicatorErrorMessage },
    );

    const [loadingMarketIndicatorByRegionCommodity, refreshMarketIndicatorByRegionCommodity, getMarketIndicatorByRegionCommodityResponse] =
        useApiWithoutAutoExecute(() => {
            const { region, subRegion, commodity, dataSource } = listMarketIndicatorsResponse!.rows!.find(
                (x) => x.marketIndicatorGuid === searchParameters.marketIndicatorGuid,
            )!;
            return demeterMarketIndicatorsApi.getMarketIndicatorByRegionAndCommodity(region, commodity, subRegion ?? undefined, dataSource ?? undefined);
        });

    const showChartLoadingSpinner = loadingRunMarketIndicator || loadingMarketIndicator || loadingMarketIndicatorByRegionCommodity;

    const [, handleUpdateMarketIndicatorFactorsGroupName] = useApiWithoutAutoExecute(
        (marketIndicatorFactorRequest?: UpdateMarketIndicatorFactorGroupRequest) =>
            demeterMarketIndicatorsApi.updateMarketIndicatorFactorGroup(
                marketIndicatorFactorRequest!.marketIndicatorGuid,
                marketIndicatorFactorRequest!.displayGroupName,
                {
                    marketIndicatorGuid: marketIndicatorFactorRequest!.marketIndicatorGuid,
                    displayGroupName: marketIndicatorFactorRequest!.displayGroupName,
                    newDisplayGroupName: marketIndicatorFactorRequest!.newDisplayGroupName,
                },
            ),
        {
            successMessage: translations.marketIndicatorsManagement.messages.factorGroupNameUpdateSuccessful,
            errorMessage: translations.marketIndicatorsManagement.messages.factorGroupNameUpdateFailed,
        },
    );

    const [, handleDeleteMarketIndicatorFactors, deleteMarketIndicatorsFactorsResponse] = useMultipleApis(
        (marketIndicatorFactorRequest?: DeleteMarketIndicatorFactorRequest | DeleteMarketIndicatorFactorRequest[]) => {
            const marketIndicatorFactorRequests = Array.isArray(marketIndicatorFactorRequest) ? marketIndicatorFactorRequest : [marketIndicatorFactorRequest];

            return marketIndicatorFactorRequests?.map((x) =>
                demeterMarketIndicatorsApi.deleteMarketIndicatorFactor(x!.marketIndicatorGuid, x!.marketIndicatorFactorGuid),
            );
        },
        {
            stopAutoExecute: true,
            successMessage: translations.marketIndicatorsManagement.messages.marketIndicatorFactorDeleteSuccessful,
            errorMessage: translations.marketIndicatorsManagement.messages.marketIndicatorFactorDeleteFailed,
        },
    );

    const marketFactorsResponse = useMemo(
        () => getMarketIndicatorResponse?.marketIndicator ?? getMarketIndicatorByRegionCommodityResponse?.marketIndicator,
        [getMarketIndicatorResponse, getMarketIndicatorByRegionCommodityResponse],
    );

    // Component states.
    const [addingFactorGroupOn, setAddingFactorGroupOn] = useState<boolean>();
    const guageChartTitleString = useMemo(() => translate(marketFactorsResponse?.displayName ?? ''), [translations, marketFactorsResponse]);

    // Wait until we have factor groups to avoid getting oops message.
    useEffect(() => {
        if (!props.isInAdministrationMode || !getMarketIndicatorResponse || getMarketIndicatorResponse?.marketIndicator?.factorGroups.length === 0) {
            return;
        }

        refreshRunMarketIndicator();
    }, [marketFactorsResponse, props.isInAdministrationMode]);

    useEffect(() => {
        if (!listMarketIndicatorsResponse || listMarketIndicatorsResponse.rows?.length === 0) {
            return;
        }

        refreshMarketIndicatorByRegionCommodity();
    }, [searchParameters.marketIndicatorGuid, listMarketIndicatorsResponse, deleteMarketIndicatorsFactorsResponse, props.isInAdministrationMode]);

    useEffect(() => {
        if (!listMarketIndicatorsResponse || listMarketIndicatorsResponse?.rows?.length === 0 || searchParameters.marketIndicatorGuid) {
            return;
        }

        if (props.isInAdministrationMode && marketIndicatorGuid) {
            setSearchParameters({ marketIndicatorGuid });
        } else {
            setSearchParameters({ marketIndicatorGuid: listMarketIndicatorsResponse?.rows![0].marketIndicatorGuid! });
        }
    }, [props.isInAdministrationMode, listMarketIndicatorsResponse]);

    const navigateBackToMarketIndicators = () => {
        setSearchParameters({ tab: 'MarketIndicators' });
        // Change this back to navigate(-1) after getting out of mono.
        navigate(NavigationRoutes.Administration);
    };

    const loading = !marketFactorsResponse || !listMarketIndicatorsResponse;

    const selectedMarketIndicator = runMarketIndicatorResponse?.marketIndicator ?? marketFactorsResponse!;
    const marketIndicatorPermissionKey = selectedMarketIndicator ? `${selectedMarketIndicator.region}_${selectedMarketIndicator.commodity}` : '';
    const isReadOnlyMode =
        usePermission(DemeterPermissionType.MarketIndicatorSubjectMatterExpert, marketIndicatorPermissionKey) &&
        currentUserType === DemeterUserType.BusinessOwner;
    const showReviewForm =
        props.isInAdministrationMode && usePermission(DemeterPermissionType.MarketIndicatorSubjectMatterExpert, marketIndicatorPermissionKey);
    const isInEditMode = props.isInAdministrationMode && usePermission(DemeterPermissionType.MarketIndicatorDeveloper);

    return loading ? (
        <PageLoadingSpinner />
    ) : (
        <div className={styles.master_page_container} data-testid="MarketIndicatorsPage">
            {props.isInAdministrationMode ? (
                <PageHeaderWithBackNavigation
                    handleBackNavigation={navigateBackToMarketIndicators}
                    title={translations.marketIndicatorsManagement.title}
                    testId="MarketIndicatorsPageHeader"
                />
            ) : (
                <>
                    <PageHeader title={translations.marketIndicators.title} testId="MarketIndicatorsPageHeader" />
                    <MenuByMarketIndicatorNavigation listMarketIndicatorsResponse={listMarketIndicatorsResponse} />
                </>
            )}
            <div className={styles.markets_management_indicator_row} data-testid="MarketsManagementIndicatorRow">
                <div className={styles.markets_management_indicators_reverse} data-testid="MarketsManagementIndicators">
                    {(runMarketIndicatorResponse?.marketIndicator?.factorGroups ?? marketFactorsResponse.factorGroups)
                        .filter((x) => x.factors.length > 0)
                        .map((x) => (
                            <MarketIndicatorFactorGroup
                                key={x.displayGroupName}
                                displayName={x.displayGroupName}
                                factors={x.factors.sort((a, b) => a.order - b.order)}
                                handleUpdateMarketIndicatorFactorsGroup={handleUpdateMarketIndicatorFactorsGroupName}
                                handleDeleteMarketIndicatorFactors={handleDeleteMarketIndicatorFactors}
                                isInEditMode={isInEditMode}
                                isInAdministrationMode={props.isInAdministrationMode}
                                isReadOnlyMode={isReadOnlyMode}
                                nextOrder={x.factors.sort((a, b) => a.order - b.order)[x.factors.length - 1].order + 1}
                                getMarketIndicatorResponse={getMarketIndicatorByRegionCommodityResponse}
                            />
                        ))}
                    {addingFactorGroupOn && (
                        <MarketIndicatorFactorGroup
                            displayName={translations.marketIndicatorsManagement.text.newFactorGroup}
                            factors={[]}
                            handleUpdateMarketIndicatorFactorsGroup={handleUpdateMarketIndicatorFactorsGroupName}
                            handleDeleteMarketIndicatorFactors={() => setAddingFactorGroupOn(false)}
                            isInEditMode={isInEditMode}
                            isInAdministrationMode={props.isInAdministrationMode}
                            nextOrder={
                                (marketFactorsResponse?.factorGroups[(marketFactorsResponse?.factorGroups.length ?? 0) - 1]?.order ?? 0) +
                                defaultNumberOfFactorsInRow
                            }
                        />
                    )}
                    {isInEditMode && marketFactorsResponse?.factorGroups.filter((x) => x.factors.length > 0).length < maximumNumberOfFactorGroups && (
                        <button type="button" onClick={() => setAddingFactorGroupOn(true)} className={styles.markets_management_add_factor_group}>
                            <img src={addNewFactorGroup} alt="#" /> {translations.marketIndicatorsManagement.text.addFactorGroup}
                        </button>
                    )}
                </div>
                <div className={styles.markets_management_guage_reverse}>
                    <MarketIndicatorGuageChart
                        title={guageChartTitleString}
                        marketIndicator={runMarketIndicatorResponse?.marketIndicator ?? marketFactorsResponse!}
                    />
                </div>
            </div>
            <div className={styles.markets_management_timeline_chart}>
                <MarketIndicatorChart
                    isLoading={showChartLoadingSpinner}
                    useColorPalletteInLegend
                    runMarketIndicatorResponse={runMarketIndicatorResponse ?? getMarketIndicatorResponse ?? getMarketIndicatorByRegionCommodityResponse}
                />
            </div>
            <MarketIndicatorReviewForm marketIndicatorGuid={marketIndicatorGuid} show={showReviewForm} />
        </div>
    );
};

export default MarketIndicatorsPage;
