import { useEffect, useState } from 'react';
import { CalculationEngineMonthlyValueOverrides, FarmerMarginCalculatorModel, RunCalculationEngineResponse } from '../../../../../Generated/Raven-Demeter';
import TextInput from '../../../../Components/Form/Inputs/TextInput';
import RegexValidators from '../../../../Core/Validation/RegexValidators';
import documentObjectModelService from '../../../../Services/DocumentObjectModelService';
import formattingService from '../../../../Services/Formatting/FormattingService';
import { DemeterCalculationEngineLineShortModelWithChildren, MarginCompositeModel, TabEventKeyType } from '../../RiskDefinitions';
import styles from '../../RiskPage.module.scss';

interface IMarginInputProps {
    index: number;
    fieldName: string;
    getFarmerMarginCalculatorCompositeRowsModel: MarginCompositeModel[];
    ancestors?: string[];
    runCalculationEngineResponse: RunCalculationEngineResponse;
    savedFarmerMarginCalculator?: FarmerMarginCalculatorModel;
    handleGridTabNavigation: (columnIndex: number, rowIndex: number, direction: TabEventKeyType) => void;
    runMonthlyValueOverrides: Array<CalculationEngineMonthlyValueOverrides> | null;
    setRunMonthlyValueOverrides: (monthlyValueOverrides: Array<CalculationEngineMonthlyValueOverrides> | null) => void;
    disabled?: boolean;
    validation?: RegExp;
}

const maximumNumberOfDecimalPlaces = 2;

const MarginInput: React.FC<IMarginInputProps> = (props: IMarginInputProps) => {
    const [textValue, setTextValue] = useState<string>('');
    const [inputUpdated, setInputUpdated] = useState(false);

    // Drill down to each child level.
    const currentSeries = props.getFarmerMarginCalculatorCompositeRowsModel.map((x) => Object.values(x))[props.index];
    const firstLevelOfSeries = Object.values(currentSeries);
    const firstLevelOfSeriesWithChildren = firstLevelOfSeries.filter((x) => x.children);
    const secondLevelOfSeries = firstLevelOfSeriesWithChildren.map((x) => x.children);
    const thirdLevelOfSeries = firstLevelOfSeriesWithChildren.map((x) =>
        (x?.children as DemeterCalculationEngineLineShortModelWithChildren[]).filter((y) => y?.children).map((y) => y?.children),
    );
    const fourthLevelOfSeries = firstLevelOfSeriesWithChildren.map((x) =>
        (x.children as DemeterCalculationEngineLineShortModelWithChildren[])
            .filter((y) => y?.children)
            .map((y) => y?.children?.filter((z) => z.children).map((z) => z?.children)),
    );
    const currentDateFlattenedCalculationEngineLineShortModels = [
        ...firstLevelOfSeries,
        ...secondLevelOfSeries,
        ...thirdLevelOfSeries,
        ...fourthLevelOfSeries,
    ].flat(3);

    const asOfDate = props.getFarmerMarginCalculatorCompositeRowsModel.map((x) => Object.values(x))[props.index][0] as string;
    const currentRunMonthlyValueOverrides = props.runMonthlyValueOverrides?.find((y) => y.asOfDate === formattingService.toApiDate(asOfDate));
    const fieldMonthlyValueOverrides = currentRunMonthlyValueOverrides?.valueOverrides[props.fieldName];

    const calculatorEngineData = props.runCalculationEngineResponse?.results?.find(
        (y) => new Date(y.asOfDate).getTime() >= new Date(asOfDate).getTime(),
    )?.lines;
    const currentCalculatorEngineValue = calculatorEngineData?.find((x) => x.variableName === props.fieldName)?.value;

    // Percent helpers.
    const isPercent = calculatorEngineData?.find((x) => x.variableName === props.fieldName)?.unitOfMeasure === 'Percent';
    const percentSibling = currentDateFlattenedCalculationEngineLineShortModels
        .filter((x) => props.ancestors?.includes(x.variableName))
        .map((x) => x?.children)
        .flat()
        .find((x) => x?.unitOfMeasure === 'Percent' && x.variableName !== props.fieldName);

    // Saved values.
    const savedMonthlyOverrides = props.savedFarmerMarginCalculator?.monthlyValueOverrides;
    const currentSavedMonthlyOverrides = savedMonthlyOverrides?.find((y) => y.asOfDate === asOfDate);
    const savedValueIsLoaded = currentSavedMonthlyOverrides?.valueOverrides[props.fieldName] === fieldMonthlyValueOverrides;

    // When we 'show/hide' and have an updated value, we set the text box to green.
    useEffect(() => {
        if (!fieldMonthlyValueOverrides || !textValue || savedValueIsLoaded) {
            return;
        }

        setInputUpdated(true);
    }, [textValue]);

    useEffect(() => {
        const valueIsSet = fieldMonthlyValueOverrides ?? currentCalculatorEngineValue;
        const percentSiblingHasChanged = isPercent && percentSibling && currentRunMonthlyValueOverrides?.valueOverrides[percentSibling.variableName];

        if (isPercent && percentSiblingHasChanged && percentSiblingHasChanged + +textValue === 100) {
            return;
        }
        // If it's updated and we have it already in the 'overrides', return.
        if (inputUpdated && fieldMonthlyValueOverrides && !percentSiblingHasChanged && !savedValueIsLoaded) {
            return;
        }

        // If there's not an override present and not a percent, set the value to the calculated value.
        if (!fieldMonthlyValueOverrides && !percentSiblingHasChanged && !savedValueIsLoaded) {
            setInputUpdated(false);
            setTextValue(`${currentCalculatorEngineValue}`);
            return;
        }

        // If the value is not set yet and not a percent.
        if (!valueIsSet && !percentSiblingHasChanged && !savedValueIsLoaded) {
            setInputUpdated(false);
            setTextValue('');
            return;
        }

        const finalTextNumberValue = fieldMonthlyValueOverrides ?? currentCalculatorEngineValue;

        if (savedValueIsLoaded) {
            setInputUpdated(false);
        }

        if (isPercent) {
            setTextValue(`${finalTextNumberValue}`);
            return;
        }

        setTextValue(`${finalTextNumberValue ? finalTextNumberValue.toFixed(maximumNumberOfDecimalPlaces) : ''}`);
    }, [props.runCalculationEngineResponse]);

    return (
        <div className={inputUpdated ? styles.calculator_table_cell_updated : styles.calculator_table_base_column}>
            <TextInput
                className={inputUpdated ? `${styles.risk_input_updated} ${styles.risk_input}` : styles.risk_input}
                type="number"
                value={textValue}
                disabled={props.disabled}
                validation={props.validation ?? RegexValidators.PositiveOrNegativeNumber}
                skipHandleChangeWhenInvalid
                handleTextChange={(value) => {
                    const numberValue = +value;
                    const valueIsOutOfBoundsForPercent = numberValue > 100 || numberValue < 0;

                    if (isPercent && valueIsOutOfBoundsForPercent) {
                        return;
                    }

                    setTextValue(value);

                    if ((numberValue === +textValue && !inputUpdated) || Number.isNaN(numberValue)) {
                        return;
                    }

                    setInputUpdated(true);

                    if (props.runMonthlyValueOverrides?.length === 0 || !props.runMonthlyValueOverrides) {
                        const newRequest = [
                            {
                                asOfDate: formattingService.toApiDate(new Date(asOfDate).toISOString()),
                                valueOverrides: { [props.fieldName]: numberValue },
                            },
                        ];

                        if (isPercent && percentSibling) {
                            newRequest[0].valueOverrides[percentSibling.variableName] = 100 - numberValue;
                        }

                        props.setRunMonthlyValueOverrides(newRequest);
                    } else if (props.runMonthlyValueOverrides) {
                        const fieldsWithOverrides = Object.keys(currentRunMonthlyValueOverrides?.valueOverrides ?? {});

                        const currentRequest =
                            props.runMonthlyValueOverrides.find((x) => x.asOfDate === formattingService.toApiDate(new Date(asOfDate).toISOString()))
                                ?.valueOverrides ?? {};

                        const newRequest = {
                            ...currentRequest,
                            [props.fieldName]: numberValue,
                        };

                        if (isPercent && percentSibling) {
                            newRequest[percentSibling.variableName] = 100 - numberValue;
                        }

                        // If it is a brand new override, remove the parents from the overrides.
                        if (!fieldsWithOverrides?.includes(props.fieldName)) {
                            props.ancestors?.forEach((x) => {
                                if (x === props.fieldName) {
                                    return;
                                }

                                delete newRequest[x as keyof typeof newRequest];
                            });
                        }

                        const currentDateCalculationEngineLineShortModel = currentDateFlattenedCalculationEngineLineShortModels.find(
                            (x) => x.variableName === props.fieldName,
                        );

                        // If it is a brand new override, remove the children and grandchildren fromt the overrides.
                        if (currentDateCalculationEngineLineShortModel.children) {
                            currentDateCalculationEngineLineShortModel.children.forEach((x: DemeterCalculationEngineLineShortModelWithChildren) => {
                                if (x.children) {
                                    x.children.forEach((y: DemeterCalculationEngineLineShortModelWithChildren) => {
                                        delete newRequest[y.variableName as keyof typeof newRequest];
                                    });
                                }

                                delete newRequest[x.variableName as keyof typeof newRequest];
                            });
                        }

                        props.setRunMonthlyValueOverrides([
                            ...props.runMonthlyValueOverrides.filter((x) => x.asOfDate !== formattingService.toApiDate(new Date(asOfDate).toISOString())),
                            {
                                asOfDate: formattingService.toApiDate(new Date(asOfDate).toISOString()),
                                valueOverrides: { ...newRequest },
                            },
                        ]);
                    }
                }}
                columnNumber={props.index}
                handleKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    if (!['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Enter'].includes(event.key)) {
                        return;
                    }

                    event.preventDefault();
                    const eventTarget = event.target as HTMLElement;
                    const columnIndex = eventTarget.getAttribute('data-column-number') ?? 0;
                    const validInputElementsOfColumn = documentObjectModelService.selectElementsWithAttributeStatus('data-column-number', columnIndex, true);
                    const rowIndex = Array.from(validInputElementsOfColumn).indexOf(eventTarget);

                    props.handleGridTabNavigation(+columnIndex, rowIndex, event.key as TabEventKeyType);
                }}
            />
        </div>
    );
};

export default MarginInput;
