import { useState } from 'react';
import DatePickerInput from '../../../../Components/Form/Inputs/DatePickerInput';
import ComponentHeader from '../../../../Components/Headers/ComponentHeader';
import formattingService from '../../../../Services/Formatting/FormattingService';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import styles from './MarginTable.module.scss';

interface IMarginTableWrapperProps {
    children: JSX.Element;
}

const MarginTableWrapper: React.FC<IMarginTableWrapperProps> = (props: IMarginTableWrapperProps) => {
    // Application hooks.
    const [translations, translate] = useLanguage();

    const [marginTableDateRange, setMarginTableDateRange] = useState({ startDate: new Date(), endDate: new Date() });

    return (
        <div className={styles.margin_table_wrapper}>
            <ComponentHeader title="Margin" />

            <div className={styles.margin_table_date_pickers}>
                <div className={styles.margin_table_date_picker_margin}>
                    <DatePickerInput
                        name="marginTableStartDate"
                        required
                        value={marginTableDateRange?.startDate as Date}
                        handleDateChange={(value) => setMarginTableDateRange({ ...marginTableDateRange, startDate: value as Date })}
                    />
                </div>
                <div className={styles.margin_table_date_picker_margin}> {translations.words.to}</div>
                <DatePickerInput
                    name="marginTableEndDate"
                    required
                    value={marginTableDateRange?.endDate as Date}
                    handleDateChange={(value) => setMarginTableDateRange({ ...marginTableDateRange, endDate: value as Date })}
                />
            </div>
            <div className={styles.margin_table_hidden_cell_1}>
                <p>432.532</p>
            </div>
            <div className={styles.margin_table_hidden_cell}>
                <p>432.532</p>
            </div>

            <div className={styles.margin_table_row}>
                <div className={styles.margin_table_header}>{formattingService.toPriceString(8434)}</div>
                <div className={styles.margin_table_header}>{formattingService.toPriceString(8434)}</div>
                <div className={styles.margin_table_header}>{formattingService.toPriceString(8434)}</div>
                <div className={styles.margin_table_header}>{formattingService.toPriceString(8434)}</div>
                <div className={styles.margin_table_header}>{formattingService.toPriceString(8434)}</div>
                <div className={styles.margin_table_header}>{formattingService.toPriceString(8434)}</div>

                <div className={styles.margin_table_header}>{formattingService.toPriceString(8434)}</div>
                <div className={styles.margin_table_header}>{formattingService.toPriceString(8434)}</div>
                <div className={styles.margin_table_header}>{formattingService.toPriceString(8434)}</div>
                <div className={styles.margin_table_header}>{formattingService.toPriceString(8434)}</div>
                <div className={styles.margin_table_header}>{formattingService.toPriceString(8434)}</div>
                <div className={styles.margin_table_header}>{formattingService.toPriceString(8434)}</div>
            </div>
            <div>{props.children}</div>
        </div>
    );
};

export default MarginTableWrapper;
