import useLanguage from '../../../../Services/Language/useLanguageHook';
import MarginTableRow from './MarginTableRow';
import MarginTableWrapper from './MarginTableWrapper';

const MarginTable: React.FC = () => {
    // Application hooks.
    const [translations, translate] = useLanguage();

    return (
        <MarginTableWrapper>
            <>
                <MarginTableRow />
                <MarginTableRow />
                <MarginTableRow />
                <MarginTableRow />
            </>
        </MarginTableWrapper>
    );
};

export default MarginTable;
