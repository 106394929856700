import Dropdown from '../../../../Components/Form/Inputs/Dropdown';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import { MarginQuarterOptions, MarginRequest } from '../../RiskDefinitions';

interface IStartingQuarterDropdownProps {
    marginRequest: MarginRequest;
    handleUpdateMarginRequest: (value: MarginRequest) => void;
    quarterOptions: MarginQuarterOptions[];
}

const StartingQuarterDropdown = (props: IStartingQuarterDropdownProps) => {
    const [translations] = useLanguage();

    return (
        <div>
            <Dropdown
                testId="StartingQuarterDropdown"
                value={props.marginRequest.startingQuarter}
                options={props.quarterOptions}
                handleOptionChange={(value) => {
                    props.handleUpdateMarginRequest({
                        ...props.marginRequest,
                        startingQuarter: value,
                    });
                }}
                label={translations.risk.margin.fields.startingQuarter}
            />
        </div>
    );
};

export default StartingQuarterDropdown;
