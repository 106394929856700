import formattingService from '../../../../Services/Formatting/FormattingService';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import styles from './MarginTable.module.scss';

const MarginTableRow: React.FC = () => {
    // Application hooks.
    const [translations, translate] = useLanguage();

    return (
        <div className={styles.margin_table_row}>
            <div className={styles.margin_table_cell_weight}>{formattingService.toPriceString(8434)}</div>
            <div className={styles.margin_table_cell_percent}>{formattingService.toPercent(50)}</div>
            <div className={styles.margin_table_cell_percent}>{formattingService.toPercent(50)}</div>
            <div className={styles.margin_table_cell_percent}>{formattingService.toPercent(50)}</div>
            <div className={styles.margin_table_cell_percent}>{formattingService.toPercent(50)}</div>
            <div className={styles.margin_table_cell_percent}>{formattingService.toPercent(50)}</div>

            <div className={styles.margin_table_cell_percent}>{formattingService.toPercent(50)}</div>
            <div className={styles.margin_table_cell_percent}>{formattingService.toPercent(50)}</div>
            <div className={styles.margin_table_cell_percent}>{formattingService.toPercent(50)}</div>
            <div className={styles.margin_table_cell_percent}>{formattingService.toPercent(50)}</div>
            <div className={styles.margin_table_cell_percent}>{formattingService.toPercent(50)}</div>
            <div className={styles.margin_table_cell_percent}>{formattingService.toPercent(50)}</div>
        </div>
    );
};

export default MarginTableRow;
