import { CalculationEngineMonthlyValueOverrides, FarmerMarginCalculatorModel, RunCalculationEngineResponse } from '../../../../../Generated/Raven-Demeter';
import { MarginCompositeModel, TabEventKeyType } from '../../RiskDefinitions';
import MarginInput from './MarginInput';

interface IMarginInputProps {
    getFarmerMarginCalculatorCompositeRowsModel: MarginCompositeModel[];
    runCalculationEngineResponse: RunCalculationEngineResponse;
    savedFarmerMarginCalculator?: FarmerMarginCalculatorModel;
    runMonthlyValueOverrides: Array<CalculationEngineMonthlyValueOverrides> | null;
    setRunMonthlyValueOverrides: (runMonthlyValueOverrides: Array<CalculationEngineMonthlyValueOverrides> | null) => void;
    className: string;
    ancestors?: string[];
    fieldName: string;
    disabled?: boolean;
    handleGridTabNavigation: (columnIndex: number, rowIndex: number, direction: TabEventKeyType) => void;
    validation?: RegExp;
}

const MarginInputRow: React.FC<IMarginInputProps> = (props: IMarginInputProps) => (
    <div className={props.className}>
        {props.getFarmerMarginCalculatorCompositeRowsModel?.map((x, index) => (
            <MarginInput
                key={`input_${props.fieldName}_${x.asOfDate}`}
                getFarmerMarginCalculatorCompositeRowsModel={props.getFarmerMarginCalculatorCompositeRowsModel}
                runCalculationEngineResponse={props.runCalculationEngineResponse}
                savedFarmerMarginCalculator={props.savedFarmerMarginCalculator}
                runMonthlyValueOverrides={props.runMonthlyValueOverrides}
                setRunMonthlyValueOverrides={props.setRunMonthlyValueOverrides}
                fieldName={props.fieldName}
                ancestors={props.ancestors}
                disabled={props.disabled}
                index={index}
                handleGridTabNavigation={props.handleGridTabNavigation}
                validation={props.validation}
            />
        ))}
    </div>
);

export default MarginInputRow;
