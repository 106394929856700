import { CalculationEngineMonthlyValueOverrides, FarmerMarginCalculatorModel, RunCalculationEngineResponse } from '../../../../../../Generated/Raven-Demeter';
import { HideGroup, MarginCompositeModel, TabEventKeyType } from '../../../RiskDefinitions';
import MarginInputRow from '../MarginInputRow';
import styles from '../MarginInputsTable.module.scss';

interface IMarginInputsTableOutputHedgedDataProps {
    getFarmerMarginCalculatorCompositeRowsModel: MarginCompositeModel[];
    runCalculationEngineResponse: RunCalculationEngineResponse;
    savedFarmerMarginCalculator?: FarmerMarginCalculatorModel;
    hideGroup: HideGroup;
    runMonthlyValueOverrides: Array<CalculationEngineMonthlyValueOverrides> | null;
    setRunMonthlyValueOverrides: (runMonthlyValueOverrides: Array<CalculationEngineMonthlyValueOverrides> | null) => void;
    handleGridTabNavigation: (columnIndex: number, rowIndex: number, direction: TabEventKeyType) => void;
}

const MarginInputsTableOutputHedgedData: React.FC<IMarginInputsTableOutputHedgedDataProps> = (props: IMarginInputsTableOutputHedgedDataProps) => {
    const firstRowModel = props.getFarmerMarginCalculatorCompositeRowsModel[0];
    const showOutputHedgedChildren = props.hideGroup[firstRowModel.outputHedgedVolume.variableName] === false;
    const topLevelBackground = styles.margin_inputs_table_top_level_background;
    const level1Background = styles.margin_inputs_table_level_1_background;
    const level2Background = styles.margin_inputs_table_level_2_background;

    return (
        <>
            {props.hideGroup[firstRowModel.outputHedgedVolume.variableName] === false &&
                firstRowModel.outputHedgedVolume.children?.map((y) => {
                    const showGroupChildren = props.hideGroup[y.variableName] === false;
                    const level1Parents = [firstRowModel.outputHedgedVolume.variableName, firstRowModel.outputHedgedPrice.variableName];
                    const finalRow = (
                        <MarginInputRow
                            className={showGroupChildren ? level2Background : level1Background}
                            getFarmerMarginCalculatorCompositeRowsModel={props.getFarmerMarginCalculatorCompositeRowsModel}
                            ancestors={level1Parents}
                            runCalculationEngineResponse={props.runCalculationEngineResponse}
                            disabled={showGroupChildren}
                            fieldName={y.variableName}
                            handleGridTabNavigation={props.handleGridTabNavigation}
                            runMonthlyValueOverrides={props.runMonthlyValueOverrides}
                            setRunMonthlyValueOverrides={props.setRunMonthlyValueOverrides}
                            savedFarmerMarginCalculator={props.savedFarmerMarginCalculator}
                        />
                    );

                    if (showGroupChildren) {
                        return (
                            <>
                                {y.children?.map((z) => {
                                    const showSecondaryGroupChildren = z.children && props.hideGroup[z.variableName] === false;

                                    return (
                                        <MarginInputRow
                                            className={showSecondaryGroupChildren ? level1Background : level2Background}
                                            getFarmerMarginCalculatorCompositeRowsModel={props.getFarmerMarginCalculatorCompositeRowsModel}
                                            ancestors={[...level1Parents, y.variableName, y.combinedWith?.variableName ?? '']}
                                            runCalculationEngineResponse={props.runCalculationEngineResponse}
                                            fieldName={z.variableName}
                                            handleGridTabNavigation={props.handleGridTabNavigation}
                                            runMonthlyValueOverrides={props.runMonthlyValueOverrides}
                                            setRunMonthlyValueOverrides={props.setRunMonthlyValueOverrides}
                                            savedFarmerMarginCalculator={props.savedFarmerMarginCalculator}
                                        />
                                    );
                                })}
                                {finalRow}
                            </>
                        );
                    }

                    return finalRow;
                })}
            <MarginInputRow
                className={showOutputHedgedChildren ? level1Background : topLevelBackground}
                getFarmerMarginCalculatorCompositeRowsModel={props.getFarmerMarginCalculatorCompositeRowsModel}
                runCalculationEngineResponse={props.runCalculationEngineResponse}
                disabled={props.hideGroup[firstRowModel.outputHedgedVolume.variableName] === false}
                fieldName={firstRowModel.outputHedgedVolume.variableName}
                handleGridTabNavigation={props.handleGridTabNavigation}
                runMonthlyValueOverrides={props.runMonthlyValueOverrides}
                setRunMonthlyValueOverrides={props.setRunMonthlyValueOverrides}
                savedFarmerMarginCalculator={props.savedFarmerMarginCalculator}
            />
            <MarginInputRow
                className={showOutputHedgedChildren ? level1Background : topLevelBackground}
                getFarmerMarginCalculatorCompositeRowsModel={props.getFarmerMarginCalculatorCompositeRowsModel}
                runCalculationEngineResponse={props.runCalculationEngineResponse}
                disabled={props.hideGroup[firstRowModel.outputHedgedVolume.variableName] === false}
                fieldName={firstRowModel.outputHedgedPrice.variableName}
                handleGridTabNavigation={props.handleGridTabNavigation}
                runMonthlyValueOverrides={props.runMonthlyValueOverrides}
                setRunMonthlyValueOverrides={props.setRunMonthlyValueOverrides}
                savedFarmerMarginCalculator={props.savedFarmerMarginCalculator}
            />
        </>
    );
};

export default MarginInputsTableOutputHedgedData;
